import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="psionic-skills"></a><h2>Psionic Skills</h2>
    <h2 style={{
      "marginTop": "-40px",
      "marginBottom": "0px"
    }}><span className="subtitle">In
Alphabetical Order</span></h2>
    <table className="monster-index" border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr valign="top">
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#autohypnosis">Autohypnosis</a>
          </td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#concentration">Concentration</a></td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#knowledge-psionics">Knowledge (Psionics)</a></td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#psicraft">Psicraft</a></td>
          <td style={{
            "width": "12%"
          }}><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="#use-psionic-device">Use Psionic Device</a></td>
          <td style={{
            "width": "12%"
          }}>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="skills-descriptions"></a><h3>SKILL DESCRIPTIONS
    </h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <p>The skills below relate
to the use of psionics. In addition to
three new skills (Autohypnosis, Psicraft, and Use Psionic Device), a
new category is provided for the Knowledge skill, and new uses are
given for Concentration.
    </p>
    <a id="table-skill-synergies"></a><p><span style={{
        "fontWeight": "bold"
      }}>Table: Skill Synergies
      </span></p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "170px"
          }}>5
or more ranks in. . .</th>
          <th>Gives a +2 bonus
on. . .</th>
        </tr>
        <tr className="odd-row">
          <td>Autohypnosis
          </td>
          <td>Knowledge
(psionics) checks 
          </td>
        </tr>
        <tr>
          <td>Concentration</td>
          <td>Autohypnosis checks</td>
        </tr>
        <tr className="odd-row">
          <td>Knowledge
(psionics)</td>
          <td>Psicraft</td>
        </tr>
        <tr>
          <td>Psicraft</td>
          <td>Use Psionic Device
checks involving power stones</td>
        </tr>
        <tr className="odd-row">
          <td>Use Psionic Device</td>
          <td>Psicraft checks to
address power stones</td>
        </tr>
      </tbody>
    </table>
    <h5><a id="autohypnosis"></a>AUTOHYPNOSIS
(WIS; TRAINED ONLY)</h5>
You have trained your mind to gain mastery over your body and the
mind&rsquo;s own deepest capabilities.
    <p><span style={{
        "fontWeight": "bold"
      }}>Check:</span>
The DC and the
effect of a successful check depend on the task you attempt.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "300px"
          }}>Task 
          </th>
          <th style={{
            "width": "200px"
          }}>DC</th>
        </tr>
        <tr className="odd-row">
          <td>Ignore caltrop
wound
          </td>
          <td>18
          </td>
        </tr>
        <tr valign="top">
          <td>Memorize</td>
          <td>15
          </td>
        </tr>
        <tr className="odd-row">
          <td>Resist dying</td>
          <td>20
          </td>
        </tr>
        <tr>
          <td>Resist fear</td>
          <td>Fear effect DC</td>
        </tr>
        <tr className="odd-row">
          <td>Tolerate poison</td>
          <td>Poison&rsquo;s
DC</td>
        </tr>
        <tr>
          <td>Willpower</td>
          <td>20</td>
        </tr>
      </tbody>
    </table>
    <a id="ignore-caltrop-wound"></a><p><span style={{
        "fontStyle": "italic"
      }}>Ignore Caltrop Wound:</span>
If you are wounded by stepping on a caltrop, your speed is reduced to
one-half normal. A successful Autohypnosis check removes this movement
penalty. The wound doesn&rsquo;t go away - it is just ignored
through
self-persuasion.</p>
    <a id="memorize"></a><p><span style={{
        "fontStyle": "italic"
      }}>Memorize:</span>
You can
attempt to memorize a long string of numbers, a long passage of verse,
or some other particularly difficult piece of information (but you
can&rsquo;t memorize magical writing or similarly exotic scripts).
Each
successful check allows you to memorize a single page of text (up to
800 words), numbers, diagrams, or sigils (even if you don&rsquo;t
recognize
their meaning). If a document is longer than one page, you can make
additional checks for each additional page. You always retain this
information; however, you can recall it only with another successful
Autohypnosis check.</p>
    <a id="resist-dying"></a><p><span style={{
        "fontStyle": "italic"
      }}>Resist Dying:</span>
You can
attempt to subconsciously prevent yourself from <a href="abilitiesAndConditions.html#dying" style={{
        "color": "rgb(87, 158, 182)"
      }}>dying</a>.
If you have
negative hit points and are losing hit points (at 1 per round, 1 per
hour), you can substitute a DC 20 Autohypnosis check for your d% roll
to see if you become <a href="abilitiesAndConditions.html#stable" style={{
        "color": "rgb(87, 158, 182)"
      }}>stable</a>.
If the check is
successful, you stop
losing hit points (you do not gain any hit points, however, as a result
of the check). You can substitute this check for the d% roll in later
rounds if you are initially unsuccessful.</p>
    <a id="resist-fear"></a><p><span style={{
        "fontStyle": "italic"
      }}>Resist Fear:</span>
In
response to any fear effect, you make a saving throw normally. If you
fail the saving throw, you can make an Autohypnosis check on your next
round even while overcome by fear. If your autohypnosis check meets or
beats the DC for the fear effect, you shrug off the fear. On a failed
check, the fear affects you normally, and you gain no further attempts
to shrug off that particular fear effect.</p>
    <a id="tolerate-poison"></a><p><span style={{
        "fontStyle": "italic"
      }}>Tolerate Poison:</span>
You
can choose to substitute an Autohypnosis check for a saving throw
against any standard poison&rsquo;s secondary damage or effect.
This
skill
has no effect on the initial saving throw against poison.</p>
    <a id="willpower"></a><p><span style={{
        "fontStyle": "italic"
      }}>Willpower:</span>
If reduced
to 0 hit points (<a href="abilitiesAndConditions.html#disabled" style={{
        "color": "rgb(87, 158, 182)"
      }}>disabled</a>),
you can make an
Autohypnosis check. If
successful, you can take a normal action while at 0 hit points without
taking 1 point of damage. You must make a check for each strenuous
action you want to take. A failed Autohypnosis check in this
circumstance carries no direct penalty - you can choose not to take the
strenuous action and thus avoid the hit point loss. If you do so
anyway, you drop to -1 hit points, as normal when disabled.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Action:</span>
None. Making
an Autohypnosis check doesn&rsquo;t require an action; it is either
a
free
action (when attempted reactively) or part of another action (when
attempted actively).</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Try Again:</span>
Yes, for
memorize and willpower uses, though a success doesn&rsquo;t cancel
the
effects of a previous failure. No for the other uses.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Synergy:</span>
If you have 5
or more ranks in Autohypnosis, you get a +2 bonus on <a href="#knowledge-psionics" style={{
        "color": "rgb(87, 158, 182)"
      }}>Knowledge
(psionics)</a> checks.</p>
    <p>If you have 5 or more
ranks in Concentration, you get a +2
bonus on Autohypnosis checks.</p>
    <h5><a id="concentration"></a>CONCENTRATION
(CON)</h5>
You are particularly good at focusing your mind. 
    <p>The following are
additional uses for the <a href="skillsAll.html#concentration" style={{
        "color": "rgb(87, 158, 182)"
      }}>concentration</a>
skill.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Check:</span>
You must make a
Concentration check whenever you might potentially be distracted (by
taking damage, by harsh weather, and so on) while engaged in some
action that requires your full attention. Such actions include
manifesting a power, concentrating on an active power, directing a
power, or using a psi-like ability.</p>
    <p>If the Concentration
check succeeds, you can continue with the
action as normal. If the check fails, the action automatically fails
and is wasted. If you were in the process of manifesting a power, the
power points are lost. If you were concentrating on an active power,
the power ends as if you had ceased concentrating on it. If you were
directing a power, the direction fails but the power remains active. If
you were using a psi-like ability, that use of the ability is lost.</p>
    <p>The table below
summarizes various types of distractions that
cause you to make a Concentration check. If the distraction occurs
while you are trying to manifest a power, you must add the level of the
power you are trying to manifest to the appropriate Concentration DC.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th>Concentration DC<sup>1</sup></th>
          <th>Distraction</th>
        </tr>
        <tr className="odd-row">
          <td>10 + damage dealt</td>
          <td>Damaged during the
action.<sup>2</sup>
          </td>
        </tr>
        <tr>
          <td>10 + half of
continuous damage dealt
          </td>
          <td>Taking continuous
damage during the action.<sup>3</sup>
          </td>
        </tr>
        <tr className="odd-row">
          <td>15 + power level 
          </td>
          <td>Attempting to
manifest a power without its display.
          </td>
        </tr>
        <tr>
          <td>15
          </td>
          <td><a href="abilitiesAndConditions.html#entangled" style={{
              "color": "rgb(87, 158, 182)"
            }}>Entangled</a>.
          </td>
        </tr>
        <tr className="odd-row">
          <td>Distracting
power&rsquo;s save DC
          </td>
          <td>Distracted by
nondamaging power.<sup>4</sup>
          </td>
        </tr>
        <tr>
          <td>20</td>
          <td>Gain psionic focus.
          </td>
        </tr>
        <tr className="odd-row">
          <td>20
          </td>
          <td><a href="abilitiesAndConditions.html#grappling" style={{
              "color": "rgb(87, 158, 182)"
            }}>Grappling</a>
or <a href="abilitiesAndConditions.html#pinned" style={{
              "color": "rgb(87, 158, 182)"
            }}>pinned</a>.
(You can manifest powers
normally
unless you fail your Concentration check.)
          </td>
        </tr>
        <tr>
          <td>Distracting
power&rsquo;s save DC
          </td>
          <td>Weather caused by
power<sup>4</sup>
          </td>
        </tr>
        <tr>
          <td colSpan="2">1 <span style={{
              "fontStyle": "italic"
            }}>If
you
are trying to manifest, concentrate on, or direct a power when the
distraction occurs, add the level of the power to the indicated DC</span>
          </td>
        </tr>
        <tr>
          <td colSpan="2">2 <span style={{
              "fontStyle": "italic"
            }}>Such
as during the manifestation of a power with a manifesting time of 1
round or more. Also from an attack of opportunity or readied attack
made in response to the power being manifested (for powers with a
manifesting time of 1 action) or the action being taken (for activities
requiring no more than a full-round action).</span>
          </td>
        </tr>
        <tr>
          <td colSpan="2">3 <span style={{
              "fontStyle": "italic"
            }}>Such
as from standing in natural fire or lava.</span>
          </td>
        </tr>
        <tr>
          <td colSpan="2">4 <span style={{
              "fontStyle": "italic"
            }}>If
the
power allows no save, use the save DC it would have if it did allow a
save.</span>
          </td>
        </tr>
      </tbody>
    </table>
    <a id="gain-psionic-focus"></a><p><span style={{
        "fontStyle": "italic"
      }}>Gain Psionic Focus:</span>
Merely holding a reservoir of psionic power points in mind gives
psionic characters a special energy. Psionic characters can put that
energy to work without actually paying a power point cost-they can
become psionically focused as a special use of the Concentration skill.
      <p>{`If you have 1 or more power points available, you can meditate to
attempt to become psionically focused. The DC to become psionically
focused is 20. Meditating is a full-round action that provokes attacks
of opportunity. When you are psionically focused, you can expend your
focus on any single Concentration check you make thereafter. When you
expend your focus in this manner, your Concentration check is treated
as if you rolled a 15. It`}{`’`}{`s like taking 10, except that the
number you
add to your Concentration modifier is 15. You can also expend your
focus to gain the benefit of a psionic feat - many psionic feats are
activated in this way.`}</p>
      <p>{`Once you are psionically focused, you remain focused until you expend
your focus, become unconscious, or go to sleep (or enter a meditative
trance, in the case of elans), or until your power point reserve drops
to 0.`}</p></p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Action:</span>
Usually none.
In most cases, making a Concentration check doesn&rsquo;t require
an
action;
it is either a free action (when attempted reactively) or part of
another action (when attempted actively). Meditating to gain psionic
focus is a full-round action.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Try Again:</span>
Yes, though
a success doesn&rsquo;t cancel the effects of a previous failure,
such
as the
loss of the power points for a power being manifested or the disruption
of a power being concentrated on.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span>
You can use
Concentration to manifest a power or use a psi-like ability
defensively, so as to avoid attacks of opportunity altogether. The DC
of the check is 15 + the power&rsquo;s level. If the Concentration
check
succeeds, you can manifest normally without provoking any attacks of
opportunity. If the Concentration check fails, the power also
automatically fails and the power points are wasted, just as if your
concentration had been disrupted by a distraction.</p>
    <p>A character with the <a href="psionicFeats.html#combat-manifestation" style={{
        "color": "rgb(87, 158, 182)"
      }}>Combat Manifestation</a>
feat gets a
+4 bonus
on Concentration checks made to manifest a power or use a psi-like
ability while on the defensive or while <a href="abilitiesAndConditions.html#grappling" style={{
        "color": "rgb(87, 158, 182)"
      }}>grappling</a>
or <a href="abilitiesAndConditions.html#pinned" style={{
        "color": "rgb(87, 158, 182)"
      }}>pinned</a>.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Synergy:</span>
If you have 5
or more ranks in Concentration, you get a +2 bonus on <a href="#autohypnosis" style={{
        "color": "rgb(87, 158, 182)"
      }}>Autohypnosis</a>
checks.
    </p>
    <h5><a id="knowledge-psionics"></a>KNOWLEDGE
(PSIONICS) (INT)</h5>
Like the <a href="skillsAll.html#craft" style={{
      "color": "rgb(87, 158, 182)"
    }}>Craft</a>
and <a href="skillsAll.html#profession" style={{
      "color": "rgb(87, 158, 182)"
    }}>Profession</a>
skills, <a href="skillsAll.html#knowledge" style={{
      "color": "rgb(87, 158, 182)"
    }}>Knowledge</a>
actually encompasses a
number of unrelated skills. This entry specifically relates to the body
of lore dealing with the phenomena of psionics in all its many
manifestations.
    <p>Knowledge (psionics)
covers ancient mysteries, psionic
traditions, psychic symbols, cryptic phrases, astral constructs, and
psionic races. You can use this skill to identify psionic monsters and
their special powers or vulnerabilities.
    </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Synergy:</span>
If you have 5
or more ranks in Knowledge (psionics), you get a +2 bonus on <a href="#psicraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psicraft</a>
checks.</p>
    <p>If you have 5 or more
ranks in <a href="#autohypnosis" style={{
        "color": "rgb(87, 158, 182)"
      }}>Autohypnosis</a>,
you get a +2
bonus on Knowledge (psionics) checks.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Untrained:</span>
An
untrained Knowledge (psionics) check is simply an Intelligence check.
Without actual training, you know only common knowledge (DC 10 or
lower).</p>
    <h5><a id="psicraft"></a>PSICRAFT
(INT; TRAINED ONLY)</h5>
Use this skill to identify powers as they are manifest or powers
already in place.
    <p><span style={{
        "fontWeight": "bold"
      }}>Check:</span>
You can
identify powers and psionic effects. The DCs for Psicraft checks
relating to various tasks are summarized on the table below.
    </p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "110px"
          }}>Psicraft
DC</th>
          <th>Task</th>
        </tr>
        <tr className="odd-row">
          <td>15 + power level</td>
          <td>Identify a power
being manifested. (You must sense the
power&rsquo;s display, or see some visible effect, to identify a
power.) No
action required. No retry.
          </td>
        </tr>
        <tr>
          <td>15 + power level</td>
          <td>When manifesting <a href="psionicPowersDtoF.html#detect-psionics" style={{
              "color": "rgb(87, 158, 182)"
            }}>detect psionics</a>,
determine the
discipline involved in
the aura of a single item or creature you can see. (If the aura is not
a power effect, the DC is 15 + 1/2 manifester level.) No action
required.</td>
        </tr>
        <tr className="odd-row">
          <td>15 + power level</td>
          <td>Address a power
stone to figure out what power or
powers it contains.</td>
        </tr>
        <tr>
          <td>20 + power level</td>
          <td>Identify a power
that&rsquo;s already in place and in
effect.
You must be
able to see or detect the effects of the power. No action required. No
retry.</td>
        </tr>
        <tr className="odd-row">
          <td>20 + power level</td>
          <td>Identify materials
created or shaped by psionics, such
as noting that a
particular object was created using a metacreativity power. No action
required. No retry.</td>
        </tr>
        <tr>
          <td>25 + power level</td>
          <td>After rolling a
saving throw against a power targeted
on you, determine what that power was. No action required. No retry.</td>
        </tr>
        <tr className="odd-row">
          <td>25
          </td>
          <td>Identify a psionic
tattoo. Requires 1 minute. No retry.</td>
        </tr>
        <tr>
          <td>20
          </td>
          <td>Draw a diagram to
enhance manifestation of <a href="psionicPowersDtoF.html#dimensional-anchor-psionic" style={{
              "color": "rgb(87, 158, 182)"
            }}>psionic
dimensional anchor</a>
on a summoned creature. Requires 10 minutes. No retry. The player does
not see the result of this check.</td>
        </tr>
        <tr className="odd-row">
          <td>30 or higher</td>
          <td>Understand a
strange or unique psionic effect, such as
the effects of
an outcrop of psionically resonant crystal. Time required varies. No
retry.</td>
        </tr>
      </tbody>
    </table>
    <p>Additionally, certain
powers allow you to gain information
about psionic effects, provided that you make a successful Psicraft
check as detailed in the power description.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Action:</span>
Varies, as
noted above. </p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Try Again:</span>
See above.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span>
A psion gains
a +2 bonus on Psicraft checks when dealing with a power or effect from
his discipline.</p>
    <p>If you have the <a href="psionicFeats.html#psionic-affinity" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psionic Affinity</a>
feat, you get a
+2 bonus on
Psicraft checks.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Synergy:</span>
If you have 5
or more ranks in Psicraft, you get a +2 bonus on <a href="#use-psionic-device" style={{
        "color": "rgb(87, 158, 182)"
      }}>Use
Psionic Device</a>
checks related to power stones.</p>
    <p>If you have 5 or more
ranks in Use Psionic Device, you get a
+2 bonus on Psicraft checks to address power stones.
    </p>
    <h5><a id="use-psionic-device"></a>USE
PSIONIC DEVICE (CHA;
TRAINED ONLY)</h5>
Use this skill to activate psionic devices, including power stones
(chunks of crystal that store specific powers) and&nbsp; dorjes
(slender crystal wands charged with several uses of the same power),
that otherwise you could not activate.
    <p><span style={{
        "fontWeight": "bold"
      }}>Check:</span>
You can use
this skill to address a power stone (to learn what powers are encoded
on it) or to activate a psionic item. This skill lets you use a psionic
item as if you had the manifesting ability or class features of another
class, as if you were a different race, or as if you were a different
alignment.</p>
    <p>You make Use Psionic
Device checks each time you activate a
device such as a dorje. If you are using the check to emulate an
alignment or some other quality in an ongoing manner, you need to make
the relevant emulation checks once per hour.</p>
    <p>You must consciously
choose what to emulate. That is, you must
know what you are trying to emulate when you make an emulation check.
The DCs for various tasks involving Use Psionic Device are summarized
on the table below.</p>
    <table border="0" cellPadding="1" cellSpacing="0">
      <tbody>
        <tr>
          <th style={{
            "width": "170px"
          }}>Use
Psionic Device DC
          </th>
          <th>Task</th>
        </tr>
        <tr className="odd-row">
          <td>25
          </td>
          <td style={{
            "textAlign": "left"
          }}>Activate
blindly</td>
        </tr>
        <tr>
          <td>25 + power level</td>
          <td style={{
            "textAlign": "left"
          }}>Address
a power stone</td>
        </tr>
        <tr className="odd-row">
          <td>See text</td>
          <td style={{
            "textAlign": "left"
          }}>Emulate
an ability score</td>
        </tr>
        <tr>
          <td>30
          </td>
          <td style={{
            "textAlign": "left"
          }}>Emulate
an alignment</td>
        </tr>
        <tr className="odd-row">
          <td>20
          </td>
          <td style={{
            "textAlign": "left"
          }}>Emulate
a class feature</td>
        </tr>
        <tr>
          <td>25
          </td>
          <td style={{
            "textAlign": "left"
          }}>Emulate
a race</td>
        </tr>
        <tr className="odd-row">
          <td>20
          </td>
          <td style={{
            "textAlign": "left"
          }}>Use
a dorje</td>
        </tr>
        <tr>
          <td className="last-row">20
+ manifester level</td>
          <td className="last-row" style={{
            "textAlign": "left"
          }}>Use a power
stone</td>
        </tr>
      </tbody>
    </table>
    <p><span style={{
        "fontStyle": "italic"
      }}>Activate Blindly:</span>
Some psionic items are activated by special specific thoughts or
conceptions. You can activate such items as if you were using the
activation method, even if you&rsquo;re not and even if you
don&rsquo;t
know it.
You do have to use something equivalent. You have to wave the item
around or otherwise attempt to get it to activate. You get a special +2
bonus if you&rsquo;ve activated the item at least once before.
      <p>{`If you fail the check by 10 or more, you suffer brainburn. This
brainburn affects you in the same way as brainburn that can occur when
you attempt to manifest a power from a power stone, except that the
damage is 1d4 points per power level instead of 1d6. Brainburn damage
from activating blindly is in addition to brainburn damage from
manifesting a power from a power stone.`}</p></p>
    <a id="address-a-power-stone"></a><p><span style={{
        "fontStyle": "italic"
      }}>Address a Power Stone:</span>
Successfully addressing a power stone allows you to find out what power
or powers it contains. Doing this requires 1 minute of concentration.</p>
    <a id="emulate-an-ability-score"></a><p><span style={{
        "fontStyle": "italic"
      }}>Emulate an Ability Score:</span>
To manifest a power from a power stone, you need a high ability score
in the appropriate ability. Your effective ability score (appropriate
to the class you&rsquo;re emulating when you try to manifest the
power
from
the power stone) is your check result minus 15. If you already have a
high enough score in the appropriate ability, you don&rsquo;t need
to
make
this check.</p>
    <a id="emulate-an-alignment"></a><p><span style={{
        "fontStyle": "italic"
      }}>Emulate an Alignment:</span>
Some psionic items have positive or negative effects based on your
alignment. Use Psionic Device lets you use these items as if you were
of an alignment of your choice. You can emulate only one alignment at a
time. </p>
    <p><span style={{
        "fontStyle": "italic"
      }}>Emulate a Class Feature:</span>
Sometimes you need to use a class feature to activate a psionic item.
Your effective level in the emulated class equals your check result
minus 20. This skill does not let you use the class feature of another
class. It just lets you activate items as if you had the class feature.
      <p>{`If the class whose feature you are emulating has an alignment
requirement, you must meet it, either honestly or by emulating an
appropriate alignment as a separate check (see above).`}</p></p>
    <a id="emulate-a-race"></a><p><span style={{
        "fontStyle": "italic"
      }}>Emulate a Race:</span>
Some
psionic items work only for certain races, or work better for those of
certain races. You can use such an item as if you were a race of your
choice. You can emulate only one race at a time.</p>
    <a id="use-a-dorje"></a><p><span style={{
        "fontStyle": "italic"
      }}>Use a Dorje:</span>
Normally, to use a dorje, you must have the dorje&rsquo;s power on
your
class
power list. This use of the skill allows you to use a dorje as if you
had a particular power on your class power list. This use of the skill
applies to other power trigger psionic items, if applicable.</p>
    <a id="use-a-power-stone"></a><p><span style={{
        "fontStyle": "italic"
      }}>Use a Power Stone:</span>
Normally, to manifest a power from a power stone, you must have the
power stone&rsquo;s power on your class power list. This use of the
skill
allows you to use a power stone as if you had a particular power on
your class power list. The DC is equal to 20 + the manifester level of
the power you are trying to manifest from the power stone. 
      <span style={{
        "fontStyle": "italic"
      }}>Note:</span>
Before you use a
power stone, you must first have addressed it to determine what powers
it contains. In addition, manifesting a power from a power stone
requires a minimum score (10 + power level) in the appropriate ability.
If you don&rsquo;t have a high enough score, you must emulate the
ability
score with a separate check (see above). This use of the skill applies
to other power completion psionic items.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Action:</span>
None. The Use
Psionic Device check is made as part of the action (if any) required to
activate the psionic item.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Try Again:</span>
Yes, but if
you ever roll a natural 1 while attempting to activate an item and you
fail, you can&rsquo;t try to activate it again for a day.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Special:</span>
You cannot
take 10 with this skill.</p>
    <p>You can&rsquo;t aid
another on Use Psionic Device checks. Only
the
user of the item can attempt such a check.</p>
    <p>A character with the <a href="psionicFeats.html#psionic-affinity" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psionic Affinity</a>
feat gets a +2
bonus on
Use Psionic Device checks.</p>
    <p><span style={{
        "fontWeight": "bold"
      }}>Synergy:</span>
If you have 5
or more ranks in <a href="#psicraft" style={{
        "color": "rgb(87, 158, 182)"
      }}>Psicraft</a>,
you get a +2 bonus on Use Magic Device
checks related to power stones.</p>
    <p>If you have 5 or more
ranks in Use Psionic Device, you get a
+2 bonus on Psicraft checks to address power stones.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      